import * as React from "react"

const NotFoundPage = () => {
  return (
    <main className='main404'>
      {/* CONTENTS */}

      <section className='sec-404'>
        <div className="c-content">
          <p>404 Not Found</p>
        </div>
      </section>

      {/* CONTENTS */}
    </main>
  )
}

export default NotFoundPage
